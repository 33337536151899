import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { SapOutageReportComponent } from './sap-outage-report/sap-outage-report.component';
import { CanDeactivateGuard } from '../guards/deactivate.guard';
import { ReadOnlySapViewComponent } from './components/read-only-sap-view/read-only-sap-view.component';

const routes: Routes = [
  { path: 'reporting/sapOutage', component: SapOutageReportComponent, canActivate: [AuthGuard] },
  {
    path: 'reporting/sapView/:id',
    component: ReadOnlySapViewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportingRoutingModule { }
