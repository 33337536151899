import { Injectable } from '@angular/core';
import { BaseAppSyncService } from './base-app-sync.service';
import { AppsyncService } from './appsync.service';
import { Observable } from 'rxjs';
import { AuthApiService } from './auth-api.service';
import { ReportSapOutage } from '../model/reporting/report-sap-outage';
import { queryForSapOutages } from '../graphql/reporting/query-for-sap-outages';
import { SapBuilderService } from './sap-builder.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingApiService extends BaseAppSyncService {
  constructor(
    protected _api: AppsyncService,
    protected _authApi: AuthApiService,
    private _sapBuilder: SapBuilderService
  ) {
    super(_api);
  }

  queryForSapOutages(query: any, sort: any): Observable<ReportSapOutage[]> {
    return this.query(
      queryForSapOutages,
      { query, sort },
      (items: any[]) => items.map((i) => this._sapBuilder.buildReportSapOutage(i)),
      'queryForSapOutages'
    );
  }

}
