import { Injectable } from '@angular/core';
import { BaseTableService } from './base-table.service';
import { ReportSapOutage } from '../model/reporting/report-sap-outage';

@Injectable({
  providedIn: 'root'
})
export class ReportSapOutageTableService extends BaseTableService<ReportSapOutage> {
  constructor() {
    super();
  }
}
