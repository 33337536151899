import { Column } from '../../model/common/column';

export const SAP_OUTAGE_TABLE_DEFINITION: Column[] = [
  {
    field: 'notifNum',
    header: 'REPORTING.LABEL.OUTAGE_ID',
    mobileHeader: 'REPORTING.LABEL.OUTAGE_ID',
    formatPattern: 'text',
    width: '4%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'flocNumber',
    header: 'REPORTING.LABEL.FLOC',
    mobileHeader: 'REPORTING.LABEL.FLOC',
    formatPattern: 'text',
    width: '4%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'reqStart',
    header: 'REPORTING.LABEL.START',
    mobileHeader: 'REPORTING.LABEL.START',
    formatPattern: 'text',
    width: '5%',
    sortable: true,
    filterable: false,
    matchMode: 'contains',
  },
  {
    field: 'reqEnd',
    header: 'REPORTING.LABEL.END',
    mobileHeader: 'REPORTING.LABEL.END',
    formatPattern: 'text',
    width: '5%',
    sortable: true,
    filterable: false,
    matchMode: 'contains',
  },
  {
    field: 'funcLocDesc',
    header: 'REPORTING.LABEL.FACILITY',
    mobileHeader: 'REPORTING.LABEL.FACILITY',
    formatPattern: 'text',
    width: '15%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'shortDesc',
    header: 'REPORTING.LABEL.TITLE',
    mobileHeader: 'REPORTING.LABEL.TITLE',
    formatPattern: 'text',
    width: '10%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'taskText',
    header: 'REPORTING.LABEL.TASK_TEXT',
    mobileHeader: 'REPORTING.LABEL.TASK_TEXT',
    formatPattern: 'text',
    width: '15%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'longText',
    header: 'REPORTING.LABEL.LONG_TEXT',
    mobileHeader: 'REPORTING.LABEL.LONG_TEXT',
    formatPattern: 'text',
    width: '25%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
];
