import gql from 'graphql-tag';

export const queryForSapOutages = gql`
  query queryForSapOutages(
    $query: SapOutageSortOrQuery
    $sort: SapOutageSortOrQuery
  ) {
    queryForSapOutages(query: $query, sort: $sort) {
      id
      notifNum
      flocNumber
      reqStart
      reqEnd
      funcLocDesc
      shortDesc
      longDesc
      taskText
      longText
    }
  }
`;
