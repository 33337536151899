import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { SapApiService } from 'src/app/services/sap-api.service';
import { SapOutage } from 'src/app/model/sap/sap-outage';
import { ActionsDropdownComponent } from 'src/app/crm/containers/controller-notes/actions-dropdown/actions-dropdown.component';
import { Subscription } from 'rxjs';
import { TabService } from 'src/app/core/services/tab.service';
import { MOC_EVENT_DETAILS_CONTAINER } from 'src/app/constants/common.constants';
import { MocEventBuilderService } from 'src/app/services/moc-event-builder.service';
import { MocEventTableService } from 'src/app/services/moc-event-table.service';

@Component({
  selector: 'app-read-only-sap-view',
  templateUrl: './read-only-sap-view.component.html',
  styleUrls: ['./read-only-sap-view.component.scss'],
})
export class ReadOnlySapViewComponent implements OnInit {
  @ViewChild('actions') actionsDropdownChild!: ActionsDropdownComponent;

  isLoading = false;
  selectedOutage: SapOutage;
  canCreateMOCEvent = false;
  tabClosingSub: Subscription;

  availableActions: any[] = [];

  validTaskCodes = ['ONSC', 'ONPC'];

  @Input() id: string;
  @Input() notifNum: string;

  constructor(
    private _authApi: AuthApiService,
    private sapApiService: SapApiService,
    private _mocBuilder: MocEventBuilderService,
    private _mocTable: MocEventTableService
  ) {}

  ngOnInit(): void {
    this.setupTabClosingSubscription();

    if (this.id) {
      this.loadData(this.id);
    }

    this.canCreateMOCEvent = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateMOCEvents',
    ]);

    this.fillActionsDropdown();
  }

  loadData(id: string): void {
    this.isLoading = true;
    this.sapApiService.getSapOutage(id).subscribe((outage: SapOutage): any => {
      this.selectedOutage = outage;
      this.isLoading = false;
    }),
      (error) => console.error(error);
  }

  fillActionsDropdown() {
    this.availableActions = [];
    if (this.canCreateMOCEvent) {
      this.availableActions.push({ label: 'Create MOC Event' });
    }
  }

  onActionSelected(action: string) {
    setTimeout(() => {
      this.actionsDropdownChild.clear();
    }, 100);
    if (action === 'Create MOC Event') {
      const mocEvent = this._mocBuilder.buildMOCEventFromSapOutage(
        this.selectedOutage
      );
      this._mocTable.setSelected(mocEvent);

      const mocTab = TabService.getInstance().buildNewTab(
        MOC_EVENT_DETAILS_CONTAINER,
        true
      );
      TabService.getInstance().openTab(mocTab);
    }
  }

  setupTabClosingSubscription(): void {
    this.tabClosingSub = TabService.getInstance().attemptCloseEvent.subscribe(
      (closingTab) => {
        TabService.getInstance().closeTab(closingTab.index);
      }
    );
  }
}
