<div id="sap-outage-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  <div>
    <!-- Start Mobile Filters -->
    <p-panel
      [showHeader]="true"
      header="Filters"
      [toggleable]="true"
      *ngIf="
        elements &&
        ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])
      "
    >
      <div class="filters" *ngIf="elements">
        <div
          [ngClass]="
            !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])
              ? 'filter-row'
              : 'filter-row-small'
          "
        >
          <div class="date-selection-wrapper">
            <div class="no-padding-top-bottom date-section">
              <span class="label time-and-arrows"
                >{{ "REPORTING.LABEL.START_DATE" | translate }}:</span
              >
              <span>
                <p-calendar
                  [(ngModel)]="startDate"
                  (onSelect)="dateUpdate()"
                ></p-calendar>
              </span>
            </div>

            <div class="no-padding-top-bottom date-section">
              <span class="label"
                >{{ "REPORTING.LABEL.END_DATE" | translate }}:</span
              >
              <span>
                <p-calendar
                  [(ngModel)]="endDate"
                  (onSelect)="dateUpdate(true)"
                ></p-calendar>
              </span>
            </div>
          </div>

          <div class="aor-filter" *ngIf="aors">
            <label for="aor-sel"
              >{{ "LOCATION.LABEL.AOR" | translate }}:
            </label>
            <p-multiSelect
              [options]="aors"
              [(ngModel)]="selectedAORs"
              placeholder="Choose"
              (onChange)="handleAOROrCompanyChange()"
              [style]="{ width: '15rem' }"
              [virtualScroll]="true"
              [virtualScrollItemSize]="30"
            ></p-multiSelect>
          </div>

          <app-business-unit-filter
            (selectedBusinessUnitsChanged)="handleBusinessUnitChange()"
            #buFilter
          ></app-business-unit-filter>

          <div class="company-filter">
            <span class="label">
              {{ "REPORTING.LABEL.OUTAGE_GROUPS" | translate }}:
            </span>
            <span class="row-input" id="company-input">
              <p-multiSelect
                [options]="availableOutageGroups"
                [(ngModel)]="selectedOutageGroups"
                [placeholder]="'Choose'"
                (onChange)="handleAOROrCompanyChange()"
                [style]="{ width: '15rem' }"
                [virtualScroll]="true"
                [virtualScrollItemSize]="30"
              >
              </p-multiSelect>
            </span>
          </div>

          <div class="button-wrapper">
            <div class="refresh-clear-buttons">
              <button
                pButton
                class="width-eighty"
                label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
                icon="fa fa-fw fa-eraser"
                (click)="clearScreen()"
              ></button>
              <button
                pButton
                class="refresh-button width-twenty"
                icon="fa fa-fw fa-refresh"
                (click)="refresh()"
              ></button>
            </div>

            <div class="export-btn">
              <button
                pButton
                label="{{ 'REPORTING.BUTTONS.EXPORT_TO_CSV' | translate }}"
                (click)="exportToCSV()"
              ></button>
            </div>
          </div>
        </div>
        <div
          [ngClass]="
            !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])
              ? 'filter-row'
              : 'filter-row-small'
          "
        >
          <div
            [ngClass]="
              !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])
                ? 'pre-set-filter-buttons'
                : 'pre-set-filter-buttons-small'
            "
          >
            <div class="today-filter-btn">
              <button
                pButton
                label="{{ 'REPORTING.BUTTONS.TODAY' | translate }}"
                (click)="todayClick()"
                [ngClass]="
                  todaySelected
                    ? 'selected-button-background'
                    : 'unselected-button-background'
                "
              ></button>
            </div>

            <div class="tomorrow-filter-btn">
              <button
                pButton
                label="{{ 'REPORTING.BUTTONS.TOMORROW' | translate }}"
                (click)="tomorrowClick()"
                [ngClass]="
                  tomorrowSelected
                    ? 'selected-button-background'
                    : 'unselected-button-background'
                "
              ></button>
            </div>

            <div class="seven-day-filter-btn">
              <button
                pButton
                label="{{ 'REPORTING.BUTTONS.NEXT_7_DAYS' | translate }}"
                (click)="sevenDayClick()"
                [ngClass]="
                  sevenDaysSelected
                    ? 'selected-button-background'
                    : 'unselected-button-background'
                "
              ></button>
            </div>

            <div class="derates-filter-btn">
              <button
                pButton
                label="{{ 'REPORTING.BUTTONS.DERATES' | translate }}"
                (click)="deratesClick()"
                [ngClass]="
                  deratesSelected
                    ? 'selected-button-background'
                    : 'unselected-button-background'
                "
              ></button>
            </div>

            <div class="pipeline-maintenance-filter-btn">
              <button
                pButton
                label="{{
                  'REPORTING.BUTTONS.PIPELINE_MAINTENANCE' | translate
                }}"
                (click)="pipelineMaintenanceClick()"
                [ngClass]="
                  pipelineMaintenanceSelected
                    ? 'selected-button-background'
                    : 'unselected-button-background'
                "
              ></button>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
    <!-- End Mobile Filters -->

    <!-- Start Non Mobile Filters -->
    <div
      class="filters"
      *ngIf="
        elements &&
        !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])
      "
    >
      <div
        [ngClass]="
          !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])
            ? 'filter-row'
            : 'filter-row-small'
        "
      >
        <div class="date-selection-wrapper">
          <div class="no-padding-top-bottom date-section">
            <span class="label"
              >{{ "REPORTING.LABEL.START_DATE" | translate }}:</span
            >
            <span>
              <p-calendar
                [(ngModel)]="startDate"
                (onSelect)="dateUpdate()"
              ></p-calendar>
            </span>
          </div>

          <div class="no-padding-top-bottom date-section">
            <span class="label"
              >{{ "REPORTING.LABEL.END_DATE" | translate }}:</span
            >
            <span>
              <p-calendar
                [(ngModel)]="endDate"
                (onSelect)="dateUpdate(true)"
              ></p-calendar>
            </span>
          </div>
        </div>

        <div class="aor-filter" *ngIf="aors">
          <label for="aor-sel">{{ "LOCATION.LABEL.AOR" | translate }}: </label>
          <p-multiSelect
            [options]="aors"
            [(ngModel)]="selectedAORs"
            placeholder="Choose"
            (onChange)="handleAOROrCompanyChange()"
            [style]="{ width: '15rem' }"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
          ></p-multiSelect>
        </div>

        <app-business-unit-filter
          (selectedBusinessUnitsChanged)="handleBusinessUnitChange()"
          #buFilter
        ></app-business-unit-filter>

        <div class="company-filter">
          <span class="label">
            {{ "REPORTING.LABEL.OUTAGE_GROUPS" | translate }}:
          </span>
          <span class="row-input" id="company-input">
            <p-multiSelect
              [options]="availableOutageGroups"
              [(ngModel)]="selectedOutageGroups"
              [placeholder]="'Choose'"
              (onChange)="handleAOROrCompanyChange()"
              [style]="{ width: '15rem' }"
              [virtualScroll]="true"
              [virtualScrollItemSize]="30"
            >
            </p-multiSelect>
          </span>
        </div>

        <div class="button-wrapper">
          <div class="refresh-clear-buttons">
            <button
              pButton
              class="width-eighty"
              label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
              icon="fa fa-fw fa-eraser"
              (click)="clearScreen()"
            ></button>
            <button
              pButton
              class="refresh-button width-twenty"
              icon="fa fa-fw fa-refresh"
              (click)="refresh()"
            ></button>
          </div>

          <div class="export-btn">
            <button
              pButton
              label="{{ 'REPORTING.BUTTONS.EXPORT_TO_CSV' | translate }}"
              (click)="exportToCSV()"
            ></button>
          </div>
        </div>
      </div>
      <div
        [ngClass]="
          !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])
            ? 'filter-row'
            : 'filter-row-small'
        "
      >
        <div
          [ngClass]="
            !ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])
              ? 'pre-set-filter-buttons'
              : 'pre-set-filter-buttons-small'
          "
        >
          <div class="today-filter-btn">
            <button
              pButton
              label="{{ 'REPORTING.BUTTONS.TODAY' | translate }}"
              (click)="todayClick()"
              [ngClass]="
                todaySelected
                  ? 'selected-button-background'
                  : 'unselected-button-background'
              "
            ></button>
          </div>

          <div class="tomorrow-filter-btn">
            <button
              pButton
              label="{{ 'REPORTING.BUTTONS.TOMORROW' | translate }}"
              (click)="tomorrowClick()"
              [ngClass]="
                tomorrowSelected
                  ? 'selected-button-background'
                  : 'unselected-button-background'
              "
            ></button>
          </div>

          <div class="seven-day-filter-btn">
            <button
              pButton
              label="{{ 'REPORTING.BUTTONS.NEXT_7_DAYS' | translate }}"
              (click)="sevenDayClick()"
              [ngClass]="
                sevenDaysSelected
                  ? 'selected-button-background'
                  : 'unselected-button-background'
              "
            ></button>
          </div>

          <div class="derates-filter-btn">
            <button
              pButton
              label="{{ 'REPORTING.BUTTONS.DERATES' | translate }}"
              (click)="deratesClick()"
              [ngClass]="
                deratesSelected
                  ? 'selected-button-background'
                  : 'unselected-button-background'
              "
            ></button>
          </div>

          <div class="pipeline-maintenance-filter-btn">
            <button
              pButton
              label="{{ 'REPORTING.BUTTONS.PIPELINE_MAINTENANCE' | translate }}"
              (click)="pipelineMaintenanceClick()"
              [ngClass]="
                pipelineMaintenanceSelected
                  ? 'selected-button-background'
                  : 'unselected-button-background'
              "
            ></button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Non Mobile Filters -->

    <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
    <div [style.display]="!elements ? 'none' : ''">
      <app-general-grid
        [values]="elements"
        [columns]="columns"
        [loading]="loading"
        [deviceSize]="getDeviceSize()"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [filterable]="true"
        [screenName]="screenName"
        [lazy]="true"
        (lazyLoad)="lazyLoad($event)"
        (changeViewEvent)="openDetailedOutage($event)"
        [includeExportCSV]="true"
        [exportFilename]="getExportFileName()"
        [usePaginator]="false"
        [alwaysShowPaginator]="false"
        [whiteSpaceStyle]="'normal'"
        scrollHeight="calc(100vh - 256px)"
        #grid
      >
      </app-general-grid>
    </div>
  </div>
</div>

<p-dialog
  [modal]="true"
  [(visible)]="detailDialogVisible"
  [dismissableMask]="true"
  [header]="'Sap Outage - ' + this.selectedOutage?.notifNum"
  [styleClass]="'main-dialog'"
>
  <app-read-only-sap-view [id]="this.selectedOutage?.id" #detailedView>
  </app-read-only-sap-view>
</p-dialog>
