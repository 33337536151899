import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SapOutageReportComponent } from './sap-outage-report/sap-outage-report.component';
import { ReportingRoutingModule } from './reporting-routing.module';
import { CoreModule } from '../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { CrmModule } from '../crm/crm.module';
import { ReadOnlySapViewComponent } from './components/read-only-sap-view/read-only-sap-view.component';
import { DividerModule } from 'primeng/divider';
import { SpinnerModule } from 'primeng/spinner';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [SapOutageReportComponent, ReadOnlySapViewComponent],
  imports: [
    CommonModule,
    CoreModule,
    DividerModule,
    SpinnerModule,
    ProgressSpinnerModule,
    ReportingRoutingModule,
    CrmModule,
    TranslateModule.forChild({})
  ],
  exports: [SapOutageReportComponent, ReadOnlySapViewComponent]
})
export class ReportingModule { }
